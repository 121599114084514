@import url('https://fonts.googleapis.com/css2?family=Sora:wght@200;400;700&display=swap');

@font-face {
  font-family: "LGMedium";
  src: url("fonts/Stolzl-Medium.woff") format("woff");
  font-weight: normal;
}

@font-face {
  font-family: "LGRegular";
  src: url("fonts/Stolzl-Regular.woff") format("woff");
  font-weight: normal;
}

@font-face {
  font-family: "LGLight";
  src: url("fonts/Stolzl-Light.woff") format("woff");
  font-weight: normal;
}

@font-face {
  font-family: "LGBold";
  src: url("fonts/Stolzl-Bold.woff") format("woff");
  font-weight: normal;
}

:root {
  --screen-large: 1200px;
  --screen-medium: 992px;
  --screen-small: 600px;
  --default-font: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-medium: "Sora", var(--default-font);
  --font-light: "Sora", var(--default-font);
  --font-regular: "Sora", var(--default-font);
  --font-bold: "Sora", var(--default-font);
  --color-prim: #08B631;
  --color-light: #F0F0F0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

div {
  display: block;
}

img {
  line-height: 0;
}

html {
  font-size: 20px;

}

body {
  background: #fff;
  font-family: var(--font-regular);
  font-weight: 400;
  color: #000;
}

#sticky-header {
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: space-between;
  z-index: 900;
  padding: 40px 70px;
}

#branding {
  font-family: var(--font-medium);
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
}

#branding a {
  text-decoration: none;
  color: #000;
}

#main-nav {
  font-family: var(--font-light);
  font-weight: 300;
  text-transform: uppercase;
  display: flex;
}

#main-nav a {
  display: block;
  margin-left: 2em;
  color: #000;
  text-decoration: none;
  font-size: 0.8em;
  padding-top: 3px;
}

.nav-to-page a {
  cursor: pointer;
  text-decoration: none;
  color: var(--color-prim);
}

/*
UTILITIES
 */

.prose {
  line-height: 2rem;
  max-width: 60vw;
}

.text-center {
  text-align: center;
}

.full {
  min-height: 100vh;
  width: 100%;
  display: block;
}

.gpad {
  padding: 60px 70px;
}

.gpad-2x {
  padding: 120px 70px;
}

.container {
  width: 100%;
  max-width: var(--screen-large);
}

.col-mid-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.row-mid-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.col-align-center {
  align-items: center;
}

.col-mid-content h1,
.row-mid-content h1 {
  font-family: var(--font-medium);
  font-weight: 700;
  font-size: 6.75rem;
  line-height: 1.11em;
}

.col-mid-content h2 {
  font-family: var(--font-medium);
  font-weight: 700;
  font-size: 4.25rem;
  line-height: 1.5em;
  color: var(--color-prim);
}

.col-mid-content h4 {
  font-family: var(--font-light);
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 1.75em;
}

.font-light {
  font-family: var(--font-light);
  font-weight: 300;
}

.px-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.no-gap {
  gap: 0 !important;
}

/*
COMPONENTS
 */

#home-branding-overlay {
  background-image: url(images/home-bg2.png);
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: -1;
}

#contact {
  /*overflow: hidden;*/
  /*position: relative;*/
  font-size: 6.75em;
  line-height: 1.11em;
}

#contact a {
  text-decoration: none;
  color: #000;
}

#contact h1 {
  font-size: 4rem;
}

#contact h2 {
  font-size: 4rem;
}

#contact h4 {
  font-size: 1.5rem;
  font-weight: 700;
  padding-top: 2rem;
}

#hfo-triangle {
  width: calc(92vh + 5px);
  height: calc(92vh + 5px);
  background: linear-gradient(to bottom right, transparent 0%, transparent 50%, var(--color-prim) 50%, var(--color-prim) 100%);
  position: absolute;
  right: 0;
  bottom: -200px;
  z-index: 1;
}

#hfo-icon {
  background-image: url(images/home-bg-black.png);
  background-position: top right;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: -200px;
  right: 0;
  z-index: 1;
}

#hfo-content {
  /*position: fixed;*/
  /*z-index: 500;*/
}

#hfo-icontent {
  /*position: fixed;*/
}

#home-page-scroller {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: visible;
  top: 0;
  left: 0;
  z-index: 500;
  content: "  ";
}

.home-plus {
  font-family: var(--font-light);
  font-weight: 300;
  color: var(--color-prim);
}

.slide-inner {
  position: relative;
  cursor: pointer;
}

.slide-inner img {
  max-height: 70vh;
  width: auto;

}

.slide-meta {
  position: absolute;
  left: -4rem;
  bottom: 7.5rem;
}

.slide-meta h1 {
  font-family: var(--font-medium);
  font-weight: 700;
  font-size: 4rem;
  line-height: 1.16em;
}

.slide-meta h4 {
  font-family: var(--font-bold);
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 2em;
  color: var(--color-prim);
  text-transform: uppercase;
}

/*.slide-meta .slide-arrow {*/
/*  font-size: 3rem;*/
/*}*/

.slide-meta .slide-arrow {
  display: inline-block;
}

.slide-meta .slide-arrow img {
  max-width: 1.5rem;
  display: block;
}

.parallax-bg {
  position: fixed;
  top: 0;
  left: 0;
  background-position: center center;
  background-size: cover;
  z-index: -200;
}

.non-parallax {
  background-color: #fff;
  background-image: url(images/home-bg2.png);
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: fixed;
}

.gallery-header {
  color: #fff;
  text-align: center;
}

.gallery-header-content {
  position: fixed;
  z-index: -1;
}

.gh-triangle {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.gh-triangle img {
  object-fit: cover;
  display: block;
  width: inherit;
  height: inherit;
  clip-path: polygon(0% 0%, 100% 0%, 100% 25%, 50% 100%, 0% 25%);
  /*clip-path: polygon(0% 0%, 100% 0%, 50% 80%);*/
}

.gallery-header h2 {
  font-size: 1rem;
  font-family: var(--font-regular);
  font-weight: 400;
  line-height: 1.5rem;
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  gap: 1em;
}

.gallery-header h2.no-margin {
  margin-top: 0;
}

.gallery-brand {
  gap: 7rem;
}

.gallery-brand img {
  display: block;
}

.gb-colors {
  display: flex;
}

.gb-colors img {
  max-width: 4rem;
}

.gb-logo {
  max-width: 40vw;
}

video {
  width: 100%;
  height: auto;
}

.gallery-slide {
  background-color: #fff;
}

.full-image-slide {
  line-height: 0;
}

.gallery-slide img {
  width: 100%;
  height: auto;
}

.gallery-row-slide {
  gap: 70px;
}

.grs-item {
  line-height: 0;
}

.grs-outer {
  gap: 70px;
}

.grs-1-3 {
  gap: 70px;
}

.grs-1-3 img {
  width: calc(100% - 20px);
  height: auto;
}

.gallery-accent {
  background-color: var(--color-light);
}

.gs-accent-rogue {
  background-color: #FCDC55;
}

.gallery-slider {
  padding: 120px 100px;
}

.gallery-slide-caption {
  text-align: center;
  font-family: var(--font-light);
  font-weight: 300;
  padding: 2rem 0;
}

.gallery-slider-bar {
  line-height: 0;
}

.gallery-slider-pages {
  text-align: center;
  -webkit-box-shadow: 0px 10px 40px -10px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 10px 40px -10px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 10px 40px -10px rgba(0, 0, 0, 0.4);
  width: 100%;
  line-height: 0;
}

.gallery-slider-pages img {
  height: auto;
  width: 100%;
}

.gallery-slider-controls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
  gap: 0.5rem;
}

.gallery-slider-controls img {
  width: 2.5rem;
  height: auto;
}

.gsc-pages {
  font-family: var(--font-light);
  font-weight: 300;
  letter-spacing: 0.9rem;
  font-size: 0.9rem;
}

.gallery-slider-controls img {
  cursor: pointer;
}


.horizontal-slider img {
  max-height: 70vh;
  width: auto;
  margin: 0 140px 0 0;
  border-radius: 2rem;
  pointer-events: none;
  display: block;
}

.horizontal-slider img:first-child {
  margin-left: 140px;
}

.hs-container {
  display: flex;
  overflow: auto;
  cursor: grab;
  -webkit-overflow-scrolling: touch;
}

.hs-scroller {
  display: flex;
  flex-wrap: nowrap;
  padding: 70px 0;
}

.askmara-items img {
  border-radius: 2rem;
  -webkit-box-shadow: 0px 10px 40px -10px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 10px 40px -10px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 10px 40px -10px rgba(0, 0, 0, 0.4);
  max-height: 125vh;
  width: auto;
}

.graphic-items img {
  max-width: 60vh;
  height: auto;
}

.logocol-items {
  gap: 10rem;
}

.logocol-items img {
  max-width: 10rem;
  height: auto;
}

.logocol-carnival {
  background-color: #E7E7E7;
}

.gallery-bottom-nav {
  display: flex;
  align-items: center;
  width: 100%;
  /*background: transparent;*/
}

.gbn-wrapper {
  display: flex;
  flex-direction: column;
  width: 33%;
  flex-shrink: 0;
}

.gbn-wrapper-right {
  text-align: right;
}

.gbn-title {
  font-family: var(--font-bold);
  font-weight: 700;
  font-size: 1.2rem;
  height: 1.2rem;
}

.gbn-dir, .gbn-arrow {
  font-family: var(--font-bold);
  font-weight: 700;
  color: var(--color-prim);
  font-size: 3.5rem;
  line-height: 3.5rem;
  margin: 1rem -4px 0;

}

.gbn-dir span {
  cursor: pointer;
  display: inline-block;
}

.gbn-arrow {
  margin-top: -1.5rem;

}

.gbn-arrow img {
  width: 2rem;
  margin: 0 0.4rem;
  cursor: pointer;
}

.gbn-image-wrapper {

}

.gbn-proj-image {
  height: calc(30vw);
  width: calc(30vw);
}

.gbn-mid-wrapper {
  flex-grow: 1;
}

#hfo-socials {
  display: flex;
  gap: 1.5rem;
  font-size: 1rem;
  line-height: 1rem;
  align-items: center;
  margin-top: 1rem;
}

#hfo-socials img {
  width: 2.5rem;
  height: auto;
}

#hfo-version {
  font-size: 0.75rem;
  line-height: 0.75rem;
  font-family: var(--font-light);
  font-weight: 300;
  position: absolute;
  lef: 0;
  bottom: 70px;
}

video {
  max-height: 80vh;
}


/**********************************
  RESPONSIVE
 *********************************/

@media (max-width: 1200px) {
}

@media (max-width: 992px) {
  .gpad {
    padding: 40px 50px;
  }

  #sticky-header {
    padding: 40px 50px;
  }

  .gpad-2x {
    padding: 100px 50px;
  }

  col-mid-content h1,
  .row-mid-content h1 {
    font-size: 5.25rem;
    line-height: 1.11em;
  }

  .slide-inner img {
    max-height: none;
    width: 80vw;
  }

  .slide-meta {
    bottom: 3rem;
    padding-left: 2rem;
  }

  .slide-meta h1 {
    font-size: 4rem !important;
    line-height: 1.16em;
  }

  .col-mid-content h1,
  .row-mid-content h1 {
    font-size: 5.25rem;
    line-height: 1.11em;
  }

  .prose {
    max-width: 70vw;
  }

  .gb-logo {
    max-width: 65vw;
  }

  #contact h1 {
    font-size: 4rem;
  }

  #contact h2 {
    font-size: 2.75rem;
  }

  #contact h4 {
    font-size: 1.1rem;
  }

  #hfo-socials img {
    width: 1.75rem;
  }

  #hfo-triangle, #hfo-icon {
    width: calc(72vh);
    height: calc(72vh);
  }

  .gbn-wrapper {
    width: 50%;
  }

  .gbn-wrapper.row-mid-content {
    display: none;
  }

  .horizontal-slider img:first-child {
    margin-left: 3rem;
  }

  .horizontal-slider img {
    margin-right: 3rem;
  }

  .askmara-items img {
    width: 40vw;
    height: auto;
    max-height: none;
  }

  .gallery-slider {
    min-height: initial;
  }

  .gallery-slide {
    min-height: 50vh;
  }

  .gallery-slide.full-image-slide {
    min-height: initial;
  }

  .logocol-carnival {
    min-height: 50vh !important;
  }

  .gallery-bottom-nav {
    min-height: initial;
  }

  .gallery-brand {
    min-height: 70vh;
  }
}

@media (max-width: 600px) {
  html {
    font-size: 15px;
  }

  #branding, #main-nav {
    font-size: 20px;
  }

  .gpad, .gpad-2x, .gallery-slider {
    padding: 50px 25px;
  }

  #sticky-header {
    padding: 25px;
  }

  .gallery-row-slide {
    gap: 20px;
  }

  .gallery-slider {
    min-height: initial;
  }

  .slide-inner img {
    max-height: none;
    width: 80vw;
  }

  .slide-meta {
    left: 0;
    bottom: 3rem;
    position: relative;
    padding-left: 0;
  }

  .gb-logo {
    max-width: 80vw;
  }

  .gallery-row-slide {
    flex-direction: column;
  }

  .grs-1-3 img {
    width: 100%;
  }

  .grs-outer, .grs-1-3, .askmara-items {
    flex-direction: column;
    gap: 20px;
  }

  .col-mid-content h1,
  .row-mid-content h1 {
    font-size: 4rem;
    line-height: 1.11em;
  }

  .gallery-bottom-nav {
    padding-top: 4rem;
  }

  .gbn-proj-image {
    display: none;
  }

  .logocol-items {
    flex-direction: row;
    gap: 40px;
  }

  .logocol-items img {
    max-width: 5rem;
  }

  #hfo-triangle, #hfo-icon {
    width: calc(100vh);
    height: calc(100vh);
  }

  .gbn-wrapper {
    width: 50%;
  }

  .gbn-wrapper.row-mid-content {
    display: none;
  }

  .horizontal-slider img:first-child {
    margin-left: 2rem;
  }

  .horizontal-slider img {
    margin-right: 2rem;
  }

  .prose {
    max-width: 100vw;
  }

  .askmara-items img {
    width: 85vw;
    height: auto;
    max-height: none;
  }

  #contact h4 {
    font-size: 1.35rem;
  }
}